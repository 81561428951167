import Image from 'next/image'

import { Stack, Tooltip, Typography } from '@mui/material'

import { useProduct } from '@/providers/p/ProductProvider'
import { useT } from '@/utils/frontend/useT'
import { getStaticAssetPath } from '@/utils/plain/getStaticAssetPath'

const PATH_TO_ICONS = '/field-of-application-icons'
const dataCy = 'FieldOfApplication'

const FieldOfApplication = () => {
  const { product } = useProduct()
  const { specificFieldsOfApplication } = product

  const { t } = useT({ keyPrefix: 'product' })

  return (
    <Stack spacing={2}>
      <Typography variant="body2bold" data-cy={`${dataCy}-fieldOfApplication-title`}>
        {t('field-of-application-title')}
      </Typography>
      <Stack direction="row" spacing={1}>
        {specificFieldsOfApplication?.map((item) => {
          const { key, label = 'label' } = item
          const src = getStaticAssetPath(`${PATH_TO_ICONS}/${key}.svg`)

          return (
            <Tooltip key={key ?? label} title={label}>
              <Image
                src={src}
                alt={label}
                width={50}
                height={50}
                data-cy={`${dataCy}-${key}`}
              />
            </Tooltip>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default FieldOfApplication
